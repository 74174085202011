<template>
  <div class="row">
    <octo-header-button :buttons="headerButtons" @onSaveSales="saveSales"/>
    <div class="col-12">
      <div class="card card-plain">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.personal_data') }}</div>
              </div>
            </template>
            <div class="row">
              <div class="col-12 col-md-6">
                <card body-classes="standard-card-body" class="shadow">
                  <div slot="header"><h4 class="card-title text-capitalize">{{ $t('page.user_data') }}</h4></div>
                  <sales-form :user="user" ref="salesForm"/>
                </card>
                <octo-entity-addresses :key="`addresses-${userDataLoadKey}`" :addresses="user.addresses"
                                       ref="octoEntityAddresses"/>
              </div>
              <div class="col-12 col-md-6">
                <octo-entity-emails :key="`emails-${userDataLoadKey}`" :emails="user.emails" ref="octoEntityEmails"/>
                <octo-entity-phones :key="`phones-${userDataLoadKey}`" :phones="user.phones" ref="octoEntityPhones"/>
              </div>
            </div>

          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.log_event') }}</div>
              </div>
            </template>
            <card body-classes="standard-card-body" class="shadow">
              <div slot="header">
                <h4 class="card-title text-capitalize">
                  {{ $t('page.user_events') }}
                </h4>
              </div>
              <user-event-log-datatable/>
            </card>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import User from "@/models/user";
import UserEventLogDatatable from "@/pages/Users/datatables/UserEventLogDatatable";
import UserWidget from "@/components/user-widget/UserWidget";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import SalesForm from "./components/SalesForm";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import {mapMutations} from "vuex";

export default {
  name: "ShowSalesPage",
  components: {
    OctoEntityPhones,
    OctoEntityEmails,
    OctoEntityAddresses,
    SalesForm,
    OctoIcon,
    Tabs,
    TabPane,
    OctoHeaderButton,
    UserWidget,
    UserEventLogDatatable,
  },
  data() {
    return {
      user: this.$_.cloneDeep(User),
      userDataLoadKey: 0,
      headerButtons: [
        {
          label: 'save',
          onClick: 'onSaveSales',
        }
      ]
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.$api.get(endpoints.USER_SHOW_SALES.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.user = resp.data.data;
        this.userDataLoadKey++;
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'users.index'})
      })
      .finally(() => {
        this.$fullLoading.hide();
      })
  },
  methods: {
    ...mapMutations({
      updateUsers: 'common/updateUsers'
    }),

    saveSales: async function () {
      try {
        this.$fullLoading.show();
        const userData = await this.$refs.salesForm.getSalesData();
        userData.emails = await this.$refs.octoEntityEmails.getEntityEmails();
        userData.phones = await this.$refs.octoEntityPhones.getEntityPhones();
        userData.addresses = await this.$refs.octoEntityAddresses.getEntityAddresses();

        const resp = await this.$api.put(
          endpoints.USER_UPDATE_SALES.replace('{id}', userData.id),
          userData
        );

        await this.updateUsers(resp?.data?.data);

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
