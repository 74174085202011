<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.firstname')">
      <template slot="value">
        <base-input maxlength="30" class="mb-0 flex-grow-1" v-model="user.firstname" type="text"/>
      </template>
    </list-group-item-component>

    <list-group-item-component :label-width="labelWidth" :label="$t('fields.lastname')">
      <template slot="value">
        <base-input maxlength="30" class="mb-0 flex-grow-1" v-model="user.lastname" type="text"/>
      </template>
    </list-group-item-component>

    <list-group-item-component :label-width="labelWidth" :label="$t('fields.call_link')">
      <template slot="value">
        <base-text-area class="flex-grow-1" v-model="user.call_link"/>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
import User from "@/models/user";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import BaseTextArea from "@/components/Inputs/BaseTextArea";

export default {
  name: "SalesForm",
  data() {
    return {
      labelWidth: 200
    }
  },
  components: {BaseTextArea, ListGroupItemComponent},
  props: {
    user: {
      type: Object,
      default: () => _.cloneDeep(User)
    }
  },
  methods: {
    getSalesData: function () {
      return this.user;
    }
  },
}
</script>

<style scoped>

</style>
